#section-hero{
    overflow: hidden;
    padding-bottom: 0;
}

.hero-container{
    display: flex;
    flex-direction: row;
    height: 780px;
}

/* .hero-title-col{
    justify-content: center;
} */

.hero-line{
    display: block;
    line-height: 1.12em;
    letter-spacing: -0.04em;
}

.hero-img{
    height: 100%;
    padding-top: 1em;
}