#section-history{
    background-image: url("../../public/picture-history.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
}

.col-history-content{
    padding: 2em;
    background-color: var(--accent);   
}

.history-container{
    display: flex;
    flex-direction: row;
}