
.header-container{
    display: flex;
    flex-direction: row;
    padding: 20px 0 10px 0;

}

.col-header-logo{
    width:fit-content;
}

.col-header-menu{
    align-items: end;
}

ul{
    list-style-type: none;
    overflow: hidden;
}

li{
    float: left;
}

/* .menu-item{
    padding: 10px 20px;
} */

/* .menu-item{
    display: block;
    padding: 10px 20px;
    border-radius: 50px / 50px;
    text-align: center;
    text-decoration: none;
    font-weight: normal;
    color: var(--primary);
}

.menu-item:hover, .menu-item:hover:visited {
    background-color: var(--accent);
    color: white;
    text-decoration: none;
}

.menu-item:visited{
    color: var(--primary);
} */
